// TODO remove after card refactor
.home-connected {
  .bloc-connected {
    h3 {
      flex-basis: 100%;
      margin-bottom: $minicard-gap;
      color: color-ui(main);
    }

    .minicard {
      @include mq('tablet-only') {
        width: 368px; // Make sure we have enough space for two minicard on one row
      }
    }
  }
}
